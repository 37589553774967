import React, { Component } from 'react'
import { func, bool, any } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

// modules
import * as actions from '@modules/redux/actions'
import Formatter from '@modules/formatter'

// components
import DisposableItem from '../DisposableItem'

// constants
const QA_PREFIX = 'qa-contact-details'
const QA_PREFIX_OUTPUT = `${QA_PREFIX}__output`
const QA_PREFIX_OUTPUT_CONTACT = `${QA_PREFIX_OUTPUT}--contact`

// accessors
const STATE_CONTACT = 'contact'

class ContactDetails extends Component {
  state = {
    [STATE_CONTACT]: null
  }

  componentDidMount() {
    const { contact, setSyntheticBreadcrumbItems } = this.props
    const contactFullName = `${contact.firstName} ${contact.lastName}`

    setSyntheticBreadcrumbItems([
      {
        key: contact.id,
        text: contactFullName
      }
    ])
  }

  componentWillUnmount() {
    const { setSyntheticBreadcrumbItems } = this.props
    setSyntheticBreadcrumbItems([])
  }

  render() {
    const { isMobileOnly, isResume, contact } = this.props

    return (
      <>
        {isMobileOnly ? (
          <>
            <DisposableItem.Decorated
              attribute="Contact Name"
              className={`${QA_PREFIX_OUTPUT_CONTACT}-name`}
            >
              {contact.firstName} {contact.lastName}
            </DisposableItem.Decorated>
            <DisposableItem.Decorated
              attribute="Contact Phone"
              className={`${QA_PREFIX_OUTPUT_CONTACT}-phone`}
            >
              {contact.phone && (
                <a href={`tel:${contact.phone}`}>
                  {Formatter.phone(contact.phone)}
                </a>
              )}
            </DisposableItem.Decorated>
            {!isResume && (
              <>
                <DisposableItem.Decorated
                  attribute="Email"
                  className={`${QA_PREFIX_OUTPUT_CONTACT}-email`}
                >
                  {contact.email && (
                    <a href={`mailto:${contact.email}`}>
                      {contact.email.toLowerCase()}
                    </a>
                  )}
                </DisposableItem.Decorated>
                <DisposableItem.Decorated
                  attribute="Relation"
                  className={`${QA_PREFIX_OUTPUT_CONTACT}-relation`}
                >
                  {contact.relation}
                </DisposableItem.Decorated>
                <DisposableItem.Decorated
                  attribute="Address"
                  className={`${QA_PREFIX_OUTPUT_CONTACT}-address`}
                >
                  {contact.address}
                </DisposableItem.Decorated>
              </>
            )}
          </>
        ) : (
          <>
            <DisposableItem
              attribute="Name"
              className={`${QA_PREFIX_OUTPUT_CONTACT}-name`}
            >
              {contact.firstName} {contact.lastName}
            </DisposableItem>
            <DisposableItem
              attribute="Phone"
              className={`${QA_PREFIX_OUTPUT_CONTACT}-phone`}
            >
              {contact.phone && (
                <a href={`tel:${contact.phone}`}>
                  {Formatter.phone(contact.phone)}
                </a>
              )}
            </DisposableItem>
            <DisposableItem
              attribute="Email"
              className={`${QA_PREFIX_OUTPUT_CONTACT}-email`}
            >
              {contact.email && (
                <a href={`mailto:${contact.email}`}>
                  {contact.email.toLowerCase()}
                </a>
              )}
            </DisposableItem>
            <DisposableItem
              attribute="Relation"
              className={`${QA_PREFIX_OUTPUT_CONTACT}-relation`}
            >
              {contact.relation}
            </DisposableItem>
            <DisposableItem
              attribute="Address"
              className={`${QA_PREFIX_OUTPUT_CONTACT}-address`}
            >
              {contact.address}
            </DisposableItem>
          </>
        )}
      </>
    )
  }
}

ContactDetails.propTypes = {
  isMobileOnly: bool,
  isResume: bool,
  setSyntheticBreadcrumbItems: func,
  contact: any
}

ContactDetails.defaultProps = {
  isMobileOnly: false,
  isResume: false,
  contact: {},
  setSyntheticBreadcrumbItems: () => null
}

const mapStateToProps = createStructuredSelector({})

export default connect(
  mapStateToProps,
  actions
)(ContactDetails)

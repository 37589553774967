import React from 'react'
import cx from 'classnames'
import { string, object, bool, array, number } from 'prop-types'

// modules
import Formatter from '@modules/formatter'
import router from '@modules/router'

// Components
import Loader from '@components/Loader'
import AdvisorCard from '@components/AdvisorCard'
import CommunityCard from '@components/CommunityCard'

import styles from './_.module.scss'

const formatCommunities = (communities, communityId, leadId) =>
  communities
    .filter(item => item.id !== communityId)
    .map(item => ({ ...item, url: `/leads/${leadId}?communityId=${item.id}` }))

const Overview = ({
  className,
  isLeadReady,
  isReferralReady,
  communities,
  advisor,
  leadId,
  activeCommunity
}) => {
  const otherCommunities =
    isLeadReady && isReferralReady
      ? formatCommunities(communities, activeCommunity.id || null, leadId || 0)
      : []
  return (
    <div className={cx('di-f', 'juco-sb', 'pab-3', className)}>
      <div className={cx(styles.cardWrapper)}>
        {isReferralReady ? (
          <CommunityCard
            onCommunityClick={link => router.go(link)}
            name={activeCommunity.name || ''}
            date={activeCommunity.status || ''}
            otherCommunities={otherCommunities}
          />
        ) : (
          <Loader.MediaObject />
        )}
      </div>

      <div className={cx(styles.cardWrapper)}>
        {isLeadReady ? (
          <AdvisorCard
            mailto={advisor.email || ''}
            name={`${advisor.firstName} ${advisor.lastName}` || ''}
            content={Formatter.phone(advisor.workPhone) || ''}
            iconName="briefcase"
          />
        ) : (
          <Loader.MediaObject />
        )}
      </div>
    </div>
  )
}

Overview.propTypes = {
  activeCommunity: object,
  advisor: object,
  className: string,
  communities: array,
  leadId: number,
  isLeadReady: bool,
  isReferralReady: bool
}

Overview.defaultProps = {
  className: '',
  activeCommunity: null,
  advisor: {},
  communities: [],
  leadId: null,
  isLeadReady: false,
  isReferralReady: false
}

export default Overview

import React from 'react'
import { Router } from '@reach/router'

// components
import IndexPage from '@compositions/Leads/components/Index'
import CommunityPage from '@compositions/Leads/components/Lead'

const LeadsPage = () => (
  <Router>
    <IndexPage path="/leads" />
    <CommunityPage path="/leads/:id" />
  </Router>
)

export default LeadsPage

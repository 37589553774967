import React from 'react'
import { string, array, object } from 'prop-types'
import cx from 'classnames'

// components
import LinesBreaker from '@components/LinesBreaker'
import ScrollableContainer from '@components/ScrollableContainer'
import Avatar from '@components/Avatar'

// modules
import Formatter from '@modules/formatter'
import DOM from '@modules/dom'

// styles
import styles from './_.module.scss'

const removeInitialLinebreaks = alert => ({...alert, advisorNote: alert.advisorNote ? alert.advisorNote.replace(/^((?:<br \/>)|(?:<br\/>))+/g, '') : ''})
const checkAdvisorNoteLength = alert => alert.advisorNote && alert.advisorNote.length > 0
const removeExtraLinebreaks = alert => ({...alert, advisorNote: alert.advisorNote.replace(/((?:<br \/>)|(?:<br\/>)){3,}/g, '<br /><br />')})

const ReferralAlert = ({referralAlert}) => (
  <section className="di-f may-4">
    {referralAlert.advisorNote && 
      <>
        <div className="max-2 mar-4">
          <Avatar.Contained
            size="sm"
            fallbackIconConfig={{}}
            className={cx(
              styles.className
            )}
            iconName="person"
          />
        </div>
        <div>
          {referralAlert.date && <header className="co-gray-3 pab-2">{Formatter.date(referralAlert.date, 'MMM DD, YYYY')}</header>}
          <LinesBreaker maxLine={6} content={referralAlert.advisorNote}/>
        </div>
      </>
    }
  </section>
)
ReferralAlert.propTypes = {
  referralAlert: object.isRequired
}

const ReferralAlerts = ({ className, referralAlerts }) => {
  const referralAlertsCopy = [...referralAlerts]
    .map(removeInitialLinebreaks)
    .filter(checkAdvisorNoteLength)
    .map(removeExtraLinebreaks)
  const noteFromAdvisor = referralAlertsCopy.length > 0 ? referralAlertsCopy.pop() : {}

  return (
    <div className={cx('pab-4', styles.note, className)}>
      {
        noteFromAdvisor.advisorNote && (
          <section className="pat-4">
            {
              noteFromAdvisor.date && <header className="co-gray-3 pab-2">{Formatter.date(noteFromAdvisor.date, 'MMM DD, YYYY')}</header>
            }
            <LinesBreaker maxLine={6} content={noteFromAdvisor.advisorNote}/>
          </section>
        )
      }

      {
        referralAlertsCopy.length > 0 && (
          <ScrollableContainer style={{ maxHeight: '20rem' }} className="mat-4 mab-2">
            {referralAlertsCopy.map(item => <ReferralAlert key={DOM.getUniqueId()} referralAlert={item}/>)}
          </ScrollableContainer>
        )
      }
    </div>
  )
}

ReferralAlerts.propTypes = {
  className: string,
  referralAlerts: array
}

ReferralAlerts.defaultProps = {
  className: '',
  referralAlerts: []
}

export default ReferralAlerts

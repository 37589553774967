import React from 'react'
import { node, string } from 'prop-types'

const HtmlParser = ({ content, className }) => {
  return <span dangerouslySetInnerHTML={{ __html: content }} className={className} />
}

HtmlParser.propTypes = {
  content: node.isRequired,
  className: string
}

HtmlParser.defaultProps = {
  className: ""
}

export default HtmlParser

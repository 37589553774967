import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { array, bool, any, func, string } from 'prop-types'

// modules
import { getActiveStatusesFromUrl } from './modules/helpers'
import { ALL_CODE } from './modules/constants'

// components
import Form from '@components/Form'
import Dropdown from '@components/Dropdown'
import Handler from './components/Handler'
import Checkbox, { EMPTY, FILLED, PARTIALLY_FILLED } from '@components/Checkbox'
import ListItem from './components/ListItem'

import styles from './_.module.scss'

const StatusDropdown = ({
  selected,
  error,
  loading,
  statuses,
  onEventOutside
}) => {
  const [leafs, setLeafs] = useState(new Map())

  useEffect(() => {
    const defaultLeafs = getActiveStatusesFromUrl(Number(selected), statuses)
    setLeafs(defaultLeafs)
  }, [selected, statuses])

  function handleOnClick(item) {
    return function(isActive) {
      setLeafs(prevState => {
        const mapList = new Map(Array.from(prevState.entries()))
        if (item.code === ALL_CODE) {
          if (isActive) {
            return new Map(statuses.map(item => [item.code, item]))
          } else {
            return new Map()
          }
        }
        if (isActive) {
          mapList.set(item.code, item)
        } else {
          mapList.delete(item.code)
        }
        const hasAllCode = mapList.has(ALL_CODE)
        if (mapList.size === statuses.length - 1 && !hasAllCode) {
          mapList.set(
            ALL_CODE,
            statuses.find(search => search.code === ALL_CODE)
          )
        } else if (mapList.size !== statuses.length && hasAllCode) {
          mapList.delete(ALL_CODE)
        } else if (mapList.size === 1 && hasAllCode) {
          return new Map()
        }
        return mapList
      })
    }
  }
  return (
    <Dropdown
      hideOnEventName="mousedown"
      onEventOutside={() =>
        !loading && !error && onEventOutside(Array.from(leafs.values()))
      }
      handler={isOpen => (
        <Handler isOpen={isOpen} total={leafs.size} label="Status" />
      )}
      isOpen={false}
      cardStyle={{ overflowY: 'auto' }}
    >
      <Form>
        <ul>
          {error ? (
            <ListItem className="pa-2">An error occured</ListItem>
          ) : loading ? (
            <ListItem className="pa-2">Loading...</ListItem>
          ) : (
            statuses.map((item, key) => {
              const hasAllCode = leafs.has(ALL_CODE)
              let status = hasAllCode || leafs.has(item.code) ? FILLED : EMPTY
              if (item.code === ALL_CODE) {
                if (leafs.size > 0 && leafs.size !== statuses.length) {
                  if (!hasAllCode && leafs.size === statuses.length - 1) {
                    status = FILLED
                  } else if (hasAllCode && leafs.size === 1) {
                    status = EMPTY
                  } else {
                    status = PARTIALLY_FILLED
                  }
                }
              }
              return (
                <ListItem key={key} className={styles.item}>
                  <div className="flba-a" />
                  <div className="wi-100">
                    <Checkbox
                      id={`${key}-${item.name}`}
                      className={cx('alit-c', 'pax-3', 'pay-2', {
                        'mal-2': key > 0
                      })}
                      title={item.name}
                      name="status[]"
                      value={status}
                      onClick={handleOnClick(item)}
                      hasTextColor
                    />
                  </div>
                </ListItem>
              )
            })
          )}
        </ul>
      </Form>
    </Dropdown>
  )
}

StatusDropdown.propTypes = {
  error: any,
  loading: bool,
  statuses: array,
  selected: string,
  onEventOutside: func
}

StatusDropdown.defaultProps = {
  error: null,
  loading: false,
  statuses: [],
  selected: '',
  onEventOutside: () => null
}

export default StatusDropdown

import React from 'react'
import cx from 'classnames'
import { node, func, string } from 'prop-types'

// Styles
import styles from './_.module.scss'

const Section = ({ children, className, header, onClick }) => (
  <div
    className={cx(
      'di-f',
      'fldi-c',
      'mab-4',
      'co-gray-5',
      styles.section,
      className
    )}
  >
    <div
      className={cx(
        'di-f',
        'juco-sb',
        'alit-c',
        'co-gray-4',
        'pax-4',
        'pay-2',
        'mab-2',
        'baco-gray-1',
        'fosi-1',
        styles.header
      )}
      onClick={onClick}
    >
      <span>{header}</span>
    </div>
    <div className="pax-4">{children}</div>
  </div>
)

Section.propTypes = {
  className: string,
  header: string,
  children: node,
  onClick: func
}

Section.defaultProps = {
  children: null,
  className: '',
  header: '',
  onClick: () => null
}

export default Section

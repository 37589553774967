import datejs from '@modules/datejs'
import { dateToUTC } from '@modules/time'
import { SORTBY_DATE_REFERRED, SORT_DESC } from '../../../constants'

export const DEFAULT_API_PARAMS = {
  itemsPerPage: 25,
  startDate: dateToUTC(datejs().subtract(30, 'day')),
  endDate: dateToUTC(datejs().endOf('day'))
}
export const PAGINATION_NEXT_PAGE = 'next'
export const PAGINATION_PREV_PAGE = 'prev'
export const DEFAULT_SORT_ORDER = SORT_DESC
export const DEFAULT_SORT_COLUMN = SORTBY_DATE_REFERRED
export const STATE_SORT_ORDER = 'sortOrder'
export const STATE_SORT_COLUMN = 'sortColumn'
export const STATE_COMMUNITIES = 'filterByCommunities'
export const STATE_STATUS = 'filterByStatus'
export const STATE_LEAD_STATUS = 'statusLead'
export const STATE_START_DATE = 'startDate'
export const STATE_END_DATE = 'endDate'
export const STATE_ORGANIZATION_NAME = 'STATE_ORGANIZATION_NAME'

export const TIME_PERIODS = [
  {
    label: 'Last 30 Days',
    dateTo: dateToUTC(datejs().subtract(30, 'day').startOf('day'))
  },
  {
    label: 'Last 12 Months',
    dateTo: dateToUTC(datejs().subtract(12, 'month').startOf('day'))
  },
  {
    label: 'This Month',
    dateTo: dateToUTC(datejs().set('date', 1).startOf('day'))
  }
]
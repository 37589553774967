import React, { Component } from 'react'
import { string, object } from 'prop-types'

// modules
import Url from '@modules/url'

// Components
import Layout from '@compositions/Layout'

import List from './components/List'

class Leads extends Component {
  render() {
    const { path, location } = this.props
    const { search } = location || {}

    const params = Url.parseSearch(search)

    const breadcrumbs = [{ key: 'leads', text: 'Leads', url: path }]
    return (
      <Layout className="baco-white" breadcrumbs={breadcrumbs} flagName="leads">
        <List params={params} />
      </Layout>
    )
  }
}

Leads.propTypes = {
  path: string,
  location: object
}

Leads.defaultProps = {
  path: '/',
  location: null
}

export default Leads

import React, { Component } from 'react'
import { string, object } from 'prop-types'
import qs from 'qs'

// Components
import Layout from '@compositions/Layout'
import LeadsDetailsContent from './components/LeadsDetailsContent'

// styles
import styles from './_.module.scss'

class LeadsDetails extends Component {
  render() {
    const { id, location } = this.props
    const { search } = location || {}
    const params = qs.parse(search, { ignoreQueryPrefix: true })
    const breadcrumbs = [{ key: 'leads', text: 'Leads', url: '/leads' }]
    return (
      <Layout breadcrumbs={breadcrumbs} flagName="leads">
        <div className={styles.leadsDetails}>
          <LeadsDetailsContent
            id={id}
            communityId={params.communityId || null}
          />
        </div>
      </Layout>
    )
  }
}

LeadsDetails.propTypes = {
  id: string,
  location: object
}

LeadsDetails.defaultProps = {
  id: 'random_id',
  location: null
}

export default LeadsDetails

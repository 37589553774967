import React from 'react'
import cx from 'classnames'
import { string } from 'prop-types'

// components
import { Item } from '@components/Details'
import DisposableItem from '../DisposableItem'

const Decorated = props => (
  <DisposableItem
    {...props}
    align={Item.ALIGN_HORIZONTAL}
    className={cx('mab-2', 'co-black', props.className)}
  />
)

Decorated.propTypes = {
  className: string
}

Decorated.defaultProps = {
  className: ''
}

export default Decorated

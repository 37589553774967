import React from 'react'
import { any, string } from 'prop-types'

import { Item } from '@components/Details'

const DisposableItem = props => {
  return props.children ? <Item {...props} /> : null
}

DisposableItem.propTypes = {
  children: any,
  attribute: string
}

DisposableItem.defaultProps = {
  children: null,
  attribute: ''
}

export default DisposableItem

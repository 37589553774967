import qs from 'qs'

import router from '@modules/router'
import { REST_API_ENDPOINTS, API_VERSION } from '@modules/constants'
import datejs from '@modules/datejs'
import { dateToUTC } from '@modules/time'
import { SORT_ASC, SORT_DESC } from '../../../constants'

import {
  DEFAULT_SORT_ORDER,
  DEFAULT_API_PARAMS,
  STATE_SORT_ORDER,
  STATE_SORT_COLUMN,
  PAGINATION_NEXT_PAGE,
  PAGINATION_PREV_PAGE
} from '../constants'

export const getParamFilters = ({
  communities,
  fetchCollection,
  status,
  startDate,
  endDate,
  ...rest
}) => {
  const startDateUTC =
    (startDate && dateToUTC(startDate)) || DEFAULT_API_PARAMS.startDate
  const endDateUTC =
    (endDate && dateToUTC(endDate)) || DEFAULT_API_PARAMS.endDate

  return {
    ...DEFAULT_API_PARAMS,
    page: 1,
    communitiesIds: communities,
    statusFlags: status,
    startDate: startDateUTC,
    endDate: endDateUTC,
    ...rest
  }
}

export const fetchData = ({ fetchCollection, ...rest }, cb) => {
  const config = {
    params: getParamFilters({ ...rest }),
    apiVersion: API_VERSION
  }

  fetchCollection(REST_API_ENDPOINTS.referrals, config, cb)
}

export const fetchBreakdownOfLeadCounts = (
  { communities, startDate, endDate, fetchCollection },
  cb
) => {
  const config = {
    params: {
      ...DEFAULT_API_PARAMS,
      startDate:
        (startDate && dateToUTC(startDate)) || DEFAULT_API_PARAMS.startDate,
      endDate: (endDate && dateToUTC(endDate)) || DEFAULT_API_PARAMS.endDate,
      communitiesIds: communities
    },
    apiVersion: API_VERSION
  }

  fetchCollection(REST_API_ENDPOINTS.breakdownStatusCounts, config, cb)
}
export const onChangeSort = (newSortColumn, state, cb = () => null) => {
  const {
    [STATE_SORT_COLUMN]: sortColumn,
    [STATE_SORT_ORDER]: sortOrder
  } = state
  const sortOrderInverse = sortOrder === SORT_ASC ? SORT_DESC : SORT_ASC
  cb({
    [STATE_SORT_ORDER]:
      sortColumn === newSortColumn ? sortOrderInverse : DEFAULT_SORT_ORDER,
    [STATE_SORT_COLUMN]: newSortColumn
  })
}

export const onFilterByCommunities = (
  selectedCommunities = [],
  dropDownCommunities = [],
  cb = () => null
) => {
  let shouldUpdate = selectedCommunities.length !== dropDownCommunities.length
  if (!shouldUpdate) {
    const diff = selectedCommunities.filter(
      item => dropDownCommunities.indexOf(item) !== -1
    )
    shouldUpdate = diff.length !== selectedCommunities.length
  }
  cb(shouldUpdate, (shouldUpdate && dropDownCommunities) || [])
}

export const getValidStartDate = date => {
  const twelveMonthsAgo = datejs().subtract(12, 'month')
  let startDate = datejs(date)
  if (date && startDate.isBefore(twelveMonthsAgo)) {
    startDate = twelveMonthsAgo
  }
  return startDate
}
export const goToPage = (
  referrals,
  {
    communities,
    sortOrder,
    sortColumn,
    page,
    direction,
    status,
    startDate: startDateParam,
    endDate: endDateParam,
    resetPagination = false
  }
) => {
  const { meta } = referrals
  page = page || (meta && meta.page)

  if (page && direction) {
    if (direction === PAGINATION_NEXT_PAGE) {
      page++
    } else if (direction === PAGINATION_PREV_PAGE) {
      page--
    }
  }
  if (resetPagination) {
    page = 1
  }

  if (page !== null) {
    const params = {
      communities,
      page,
      sortOrder,
      sortColumn,
      status
    }
    if (startDateParam) {
      const startDate = getValidStartDate(startDateParam)
      params['startDate'] = dateToUTC(startDate)
    }
    if (endDateParam) {
      params['endDate'] = dateToUTC(endDateParam)
    }
    const stringify = qs.stringify(params, {
      arrayFormat: 'repeat',
      addQueryPrefix: true
    })
    const url = `/leads${stringify}`
    router.go(url)
  }
}

export const getStatusName = status =>
  `${status}`.indexOf('#status-') === -1 ? `#status-${status}` : status

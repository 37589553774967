import React from 'react'
import cx from 'classnames'

// Components
import Avatar from '@components/Avatar'
import MediaObject, { Sidebar, Content } from '@components/MediaObject'
import { string, node, bool, object, func } from 'prop-types'

// Styles
import styles from './_.module.scss'

const QA_PREFIX = 'qa-advisor-card'

const AdvisorCard = ({
  avatarConfig,
  config,
  chipComponent,
  content,
  date,
  displayTitle,
  listComponent,
  mailto,
  name,
  iconName,
  className,
  onClick
}) => {
  return (
    <div
      className={cx(
        'di-f',
        'fldi-c',
        'teal-l',
        styles.advisorCard,
        className,
        QA_PREFIX
      )}
      onClick={onClick}
    >
      {displayTitle && <h3 className="co-gray-3 pat-2">Advisor</h3>}
      <MediaObject alignCenter={false}>
        <Sidebar width={60}>
          <Avatar.Contained
            size="sm"
            fallbackIconConfig={{}}
            className={cx(
              `${QA_PREFIX}__output--avatar`,
              avatarConfig.className
            )}
            iconName={iconName}
          />
        </Sidebar>

        <Content>
          <div className={cx('di-f', 'fldi-c', 'fosi-2', styles.content)}>
            <span className="fowe-b di-f fldi-r alit-c">
              {name && (
                <span
                  className={cx(
                    config.advisorNameSpacing || 'par-2',
                    config.advisorNameColor || 'co-black',
                    `${QA_PREFIX}__output--name`
                  )}
                >
                  {name}
                </span>
              )}
              {(date && (
                <span
                  className={`co-white-s-4 fosi-1 fowe-n co-gray-3 ${QA_PREFIX}__output--date`}
                >
                  {date}
                </span>
              )) ||
                ''}
              {(chipComponent && (
                <div className={styles.chipAdvisor}>{chipComponent}</div>
              )) ||
                ''}
            </span>
            {mailto && (
              <a
                href={`mailto:${mailto}`}
                className={`${QA_PREFIX}__output--email`}
              >
                {mailto}
              </a>
            )}
            <span
              className={`whsp-pw ${
                mailto || chipComponent ? '' : 'co-gray-3'
              } ${QA_PREFIX}__output--content`}
            >
              {content}
            </span>
          </div>
        </Content>
        {listComponent && <Content width={10}>{listComponent}</Content>}
      </MediaObject>
    </div>
  )
}

AdvisorCard.propTypes = {
  avatarConfig: object,
  chipComponent: node,
  className: string,
  config: object,
  content: node,
  date: string,
  displayTitle: bool,
  iconName: string,
  listComponent: node,
  mailto: string,
  name: string,
  onClick: func
}

AdvisorCard.defaultProps = {
  chipComponent: null,
  className: '',
  content: null,
  date: '',
  displayTitle: true,
  listComponent: null,
  mailto: '',
  name: '',
  picture: '',
  avatarConfig: {},
  config: {},
  onClick: () => null,
  iconName: 'house'
}

export default AdvisorCard

import React from 'react'
import cx from 'classnames'
import { string, array, func } from 'prop-types'

// components
import Avatar from '@components/Avatar'
import MediaObject, { Sidebar, Content } from '@components/MediaObject'
import Label from '@components/Label'

// styles
import styles from './_.module.scss'

const QA_PREFIX = 'qa-community-card'

const CommunityCard = ({ name, date, otherCommunities, onCommunityClick }) => {
  return (
    <div
      className={cx(
        'di-f',
        'fldi-c',
        'teal-l',
        'pab-2',
        styles.communityCard,
        QA_PREFIX
      )}
    >
      <h3 className="co-gray-3 pat-2">Communities</h3>
      <MediaObject alignCenter={false}>
        <Sidebar width="auto" className={styles.iconContainer}>
          <Avatar.Contained iconName="house" size="sm" />
        </Sidebar>
        <Content>
          <div className="di-f fldi-c">
            <span
              className={cx(
                'fowe-b',
                'mab-2',
                'fosi-2',
                'co-black',
                styles.communityName,
                `${QA_PREFIX}__output--name`
              )}
            >
              {name}
            </span>
            <div className={cx('di-f', styles.communityLabel)}>
              {date && (
                <Label
                  size={2}
                  className={cx(
                    'pay-1',
                    'co-orange-3',
                    styles.labelCard,
                    `${QA_PREFIX}__output--label`
                  )}
                >
                  {date}
                </Label>
              )}
            </div>
            {otherCommunities.length > 0 && (
              <div className={cx('di-f', 'fldi-c')}>
                <span className="fosi-2 co-gray-4">
                  Other Referred Communities
                </span>
                {otherCommunities.map((item, itemKey) => (
                  <a
                    href={item.url}
                    onClick={e => {
                      e.preventDefault()
                      onCommunityClick(item.url)
                    }}
                    key={itemKey}
                    className={cx(
                      'fosi-2',
                      `${QA_PREFIX}__output--othercommunities`
                    )}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            )}
          </div>
        </Content>
      </MediaObject>
    </div>
  )
}

CommunityCard.propTypes = {
  date: string,
  name: string,
  otherCommunities: array,
  onCommunityClick: func
}

CommunityCard.defaultProps = {
  date: '',
  name: '',
  otherCommunities: [],
  onCommunityClick: () => null
}

export default CommunityCard

import React from 'react'
import cx from 'classnames'
import { node, string } from 'prop-types'

// styles
import styles from './_.module.scss'

const ListWidget = ({ id, children, className, header }) => {
  return (
    <div
      id={id}
      className={cx(
        'di-f',
        'fldi-c',
        'bowi-1',
        'bost-s',
        'boco-gray-2',
        'bora-1',
        'ov-h',
        className
      )}
    >
      {header && (
        <div className={cx('baco-blue-1', styles.headerContainer)}>
          {header}
        </div>
      )}
      <div className="di-f fldi-c">{children}</div>
    </div>
  )
}

ListWidget.propTypes = {
  id: string,
  className: string,
  children: node,
  header: node
}

ListWidget.defaultProps = {
  id: null,
  className: null,
  children: null,
  header: null
}

export default ListWidget

import React from 'react'
import cx from 'classnames'
import { func, string, node, number, object } from 'prop-types'

import withThemeClassNames from '@hoc/withThemeClassNames'

// Styles
import styles from './_.module.scss'

const Label = ({
  onClick,
  themeClassNames,
  className,
  children,
  size,
  ...rest
}) => (
  <div
    className={cx(
      `pax-${size}`,
      'fosi-1',
      'no',
      'bost-s',
      'bowi-0',
      'di-f',
      'alit-c',
      'max-1',
      'mab-1',
      styles.label,
      themeClassNames.backgroundColor,
      className
    )}
    onClick={onClick}
    {...rest}
  >
    {children}
  </div>
)

Label.propTypes = {
  children: node,
  className: string,
  size: number,
  themeClassNames: object,
  onClick: func
}

Label.defaultProps = {
  onClick: () => null,
  size: 3,
  children: null,
  className: '',
  themeClassNames: {}
}

export default withThemeClassNames(Label)

import React from 'react'
import cx from 'classnames'
import { string, number, func, array, object, bool } from 'prop-types'

// components
import MediaObject from '@components/MediaObject'
import ListWidget from '@components/ListWidget'
import Card from '@components/Card'
import Radio from '@components/Radio'
import Form from '@components/Form'
import TextField from '@components/TextField'
import TextArea from '@components/TextArea'
import Button from '@components/Button'
import Icon from '@components/Icon'
import { ToastNotify } from '@compositions/Toast'

// modules
import { useMergeState } from '@modules/use-merge-state'
import { Collection } from '@modules/rest-api'
import { REST_API_ENDPOINTS, API_VERSION } from '@modules/constants'

// Styles
import styles from './_.module.scss'

import MESSAGE_TYPES from './types.json'

const OTHER_TYPE_CODE = 'PCO'
const STATE_MESSAGE_TYPE = 'messageType'
const STATE_MESSAGE = 'message'
const STATE_CAN_SUBMIT = 'canSubmit'
const STATE_FORM_IS_VALID = 'formIsValid'
const STATE_ACTION_STATUS = 'status'
const STATE_VALIDATION_ERRORS = 'validationErrors'
const STATE_REFRESH_TOKEN = 'refreshToken'

const handleTextChange = (value, setData) => {
  let canSubmit = true
  if (!value.toString().trim()) canSubmit = false
  setData({ [STATE_MESSAGE]: value, [STATE_CAN_SUBMIT]: canSubmit })
}

const handleValidSubmit = (model, setData, cb) => {
  const notesCollection = new Collection(REST_API_ENDPOINTS.notes, API_VERSION)
  const config = {
    apiVersion: API_VERSION
  }
  setData({ [STATE_ACTION_STATUS]: true })
  notesCollection.createSigned(model, config, (err, res) => {
    if (err) {
      err.response && cb(err.response.data)
      ToastNotify.error(
        'Your message was unable to be sent at this time. Please try again later.'
      )
    } else {
      ToastNotify.success('Your message was sent.')
      cb(null, res.data, model)
    }
  })
}

const MessageTypesRec = ({
  disabled,
  types,
  selected,
  onChange,
  isChildOf
}) => {
  return types.map((item, index) => {
    let isActive = selected && selected.name === item.name
    if (isChildOf) {
      const activeChild = selected && selected.childActive
      isActive = (activeChild && activeChild.name === item.name) || false
    }
    const hasChildren = !!item.types
    const label = item.label || {}
    const text = (
      <>
        {label.pre}{' '}
        <b
          className={cx('pal-1', {
            'par-1': !!label.pos
          })}
        >
          {label.text}
        </b>{' '}
        {label.pos}
      </>
    )
    return (
      <div
        key={index}
        className={cx(styles.listItem, 'fosi-2', 'cu-p', {
          [styles.listItemChild]: isChildOf
        })}
      >
        {isChildOf ? (
          <div className="di-f fldi-c pax-4 pay-2">
            <Radio
              key={index}
              id={item.name
                .split(' ')
                .join('-')
                .toLowerCase()}
              name={item.name}
              labelClassName={cx('fosi-1', 'di-i', styles.radioLabel, {
                'co-primary-3': isActive
              })}
              label={text}
              value={text}
              checked={isActive}
              hasBottonPadding={false}
              className={styles.radio}
              onChange={() =>
                !disabled &&
                onChange(isActive ? null : { ...isChildOf, childActive: item })
              }
            />
          </div>
        ) : (
          <div
            className={cx('di-f', 'pax-4', 'pay-2', {
              'co-primary-3': isActive && !hasChildren
            })}
            onClick={() => !disabled && onChange(isActive ? null : item)}
          >
            {text}
            {hasChildren && (
              <div className="di-f co-gray-4 fowe-n">
                <Icon
                  className={cx('pal-2', styles.itemChevron)}
                  name={isActive ? 'chevron-up' : 'chevron-down'}
                  size={10}
                />
              </div>
            )}
          </div>
        )}
        {hasChildren && isActive && (
          <MessageTypesRec
            types={item.types}
            isChildOf={item}
            selected={selected}
            disabled={disabled}
            onChange={onChange}
          />
        )}
      </div>
    )
  })
}

MessageTypesRec.propTypes = {
  types: array,
  selected: object,
  isChild: bool,
  disabled: bool,
  onChange: func
}

MessageTypesRec.defaultProps = {
  types: [],
  selected: null,
  isChild: false,
  disabled: false,
  onChange: () => null
}

const saveMessageCallback = (err, res, model, setData, onSendMessage) => {
  if (err) {
    if (err.errors && err.errors.located)
      return setData({ [STATE_VALIDATION_ERRORS]: err.errors.located })
    else if (err.meta && err.meta.message) {
      return setData({
        [STATE_VALIDATION_ERRORS]: { text: err.meta.message }
      })
    } else {
      console.error(err)
      return false
    }
  } else {
    setData({
      [STATE_MESSAGE]: '',
      [STATE_ACTION_STATUS]: false,
      [STATE_REFRESH_TOKEN]: Math.random()
    })
    onSendMessage(res, model)
  }
}
const SendMessage = ({ placeholder, leadId, communityId, onSendMessage }) => {
  const [data, setData] = useMergeState({
    [STATE_MESSAGE_TYPE]: null,
    [STATE_ACTION_STATUS]: false,
    [STATE_MESSAGE]: '',
    [STATE_CAN_SUBMIT]: false,
    [STATE_VALIDATION_ERRORS]: {},
    [STATE_FORM_IS_VALID]: false,
    [STATE_REFRESH_TOKEN]: null
  })

  const {
    [STATE_MESSAGE_TYPE]: selected,
    [STATE_MESSAGE]: message,
    [STATE_CAN_SUBMIT]: canSubmit,
    [STATE_ACTION_STATUS]: status,
    [STATE_VALIDATION_ERRORS]: validationErrors,
    [STATE_FORM_IS_VALID]: formIsValid,
    [STATE_REFRESH_TOKEN]: refreshToken
  } = data

  const { types } = MESSAGE_TYPES
  const { childActive } = selected || {}
  const helperText =
    selected && selected.code !== OTHER_TYPE_CODE
      ? childActive
        ? childActive.name
        : selected.name
      : ''
  let label = {}
  let code = 'PCO'
  if (childActive) {
    label = childActive.label || label
    code = childActive.code || code
  } else if (selected) {
    label = selected.label || {}
    code = selected.code || code
  }
  const textBoxTitle = (
    <>
      {label.pre} <b>{label.text}</b> {label.pos}
    </>
  )
  return (
    <MediaObject>
      <MediaObject.Sidebar width="272px">
        <ListWidget
          header={
            <ListWidget.Item className="pa-4" palette="blue" hideArrow={true}>
              <span className="co-black">Message The Advisor</span>
            </ListWidget.Item>
          }
        >
          <MessageTypesRec
            types={types}
            disabled={status}
            selected={selected}
            onChange={item => setData({ [STATE_MESSAGE_TYPE]: item })}
          />
        </ListWidget>
      </MediaObject.Sidebar>
      <MediaObject.Content>
        {(selected && !!selected.types && childActive) ||
        (selected && !selected.types) ? (
          <div
            className={cx(
              'di-f',
              'fldi-c',
              'bowi-1',
              'bost-s',
              'po-r',
              styles.sendMessage
            )}
          >
            {selected.code !== OTHER_TYPE_CODE && <h5>{textBoxTitle}</h5>}
            <Form
              className="di-f fldi-c"
              onValidSubmit={model =>
                handleValidSubmit(model, setData, (err, resp, model) =>
                  saveMessageCallback(err, resp, model, setData, onSendMessage)
                )
              }
              onValid={() => setData({ [STATE_FORM_IS_VALID]: true })}
              onInvalid={() => setData({ [STATE_FORM_IS_VALID]: false })}
            >
              {leadId && (
                <TextField
                  type="text"
                  className="di-n"
                  name="leadId"
                  value={leadId}
                />
              )}
              {communityId && (
                <TextField
                  type="text"
                  className="di-n"
                  name="communityId"
                  value={communityId}
                />
              )}
              <TextField
                type="text"
                className="di-n"
                name="subType"
                value={code}
              />
              <TextArea
                key={refreshToken}
                className={cx('fosi-1', 'pay-2', styles.textareaAutosize)}
                name="text"
                placeholder={`${placeholder}${
                  helperText
                    ? helperText.toLowerCase()
                    : 'Write a custom message...'
                }`}
                onChange={evt =>
                  handleTextChange(evt.currentTarget.value, setData)
                }
                value={message}
                validations="isExisty"
                validationError="There is not a message"
                errorMessage={validationErrors.text}
                withBorder={false}
                disabled={status}
                required
              />
              {canSubmit && formIsValid && (
                <div
                  className={cx('di-f', 'po-a', 'pat-3', styles.submitButton)}
                >
                  <Button
                    className="fldi-r"
                    type="submit"
                    size="s"
                    theme="flat"
                    palette="flat"
                    disabled={status}
                  >
                    <span className="co-primary-3">
                      {status ? 'Sending...' : 'Send Message'}
                    </span>
                    <Icon className="co-primary-3" name="send" />
                  </Button>
                </div>
              )}
            </Form>
          </div>
        ) : (
          <Card className={styles.content}>
            <span className="fosi-2 co-gray-3">
              Please select a message type from the options to the left, and
              provide more information as needed.
            </span>
          </Card>
        )}
      </MediaObject.Content>
    </MediaObject>
  )
}

SendMessage.propTypes = {
  placeholder: string,
  leadId: number,
  communityId: number,
  onSendMessage: func
}

SendMessage.defaultProps = {
  placeholder: 'Texting message of ',
  leadId: null,
  communityId: null,
  onSendMessage: () => null
}

export default SendMessage

import React from 'react'
import { node } from 'prop-types'

// Components
import Label from '@components/Label'

const Tag = ({ children }) => (
  <Label size={2} className="pay-1 co-gray-4" theme="gray">
    {children}
  </Label>
)

Tag.propTypes = {
  children: node
}

Tag.defaultProps = {
  children: null
}

export default Tag

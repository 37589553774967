import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { any, bool, func, object } from 'prop-types'
import cx from 'classnames'

// hoc
import asTogglesConsumer from '@hoc/asTogglesConsumer'

// modules
import * as selectors from '@modules/redux/selectors'
import * as actions from '@modules/redux/actions'
import { Collection } from '@modules/rest-api'

// components
import BusinessUnitsDropdown from '@compositions/BusinessUnitsDropdown'
import TimePeriodDropdown from '@compositions/TimePeriodDropdown'
import Paginator from '@components/Paginator'
import Loader from '@components/Loader'
import Table from '../Table'
import Button from '@components/Button'
import { Tabs, Tab } from '@components/Tabs'
import { THEMES } from '@components/constants'
import { REST_API_ENDPOINTS } from '@modules/constants'
import FeatureToggle from '@components/FeatureToggle'
import StatusDropdown from './components/StatusDropdown'
import Downloader from '../Downloader'

// modules
import Format from '@modules/formatter'
import {
  fetchData,
  fetchBreakdownOfLeadCounts,
  goToPage,
  onFilterByCommunities,
  getStatusName,
  getParamFilters,
  getValidStartDate
} from './modules/helpers'
import {
  DEFAULT_SORT_ORDER,
  DEFAULT_SORT_COLUMN,
  STATE_COMMUNITIES,
  STATE_SORT_ORDER,
  STATE_SORT_COLUMN,
  STATE_LEAD_STATUS,
  STATE_START_DATE,
  STATE_END_DATE,
  STATE_ORGANIZATION_NAME,
  PAGINATION_NEXT_PAGE,
  PAGINATION_PREV_PAGE,
  DEFAULT_API_PARAMS,
  TIME_PERIODS
} from './modules/constants'

// styles
import styles from './_.module.scss'

import breakdownCountsMock from './mock.json'

const STATE_TIME_PERIOD_LABEL = 'timePeriodLabel'
const LEAD_BREAKDOWN_COUNT = 'breakdownLeadsCount'

class List extends Component {
  constructor(props) {
    super(props)
    const { params } = props || {}
    const { sortOrder, sortColumn, status, communities, startDate, endDate } =
      params || {}
    let communitiesList = communities
    if (typeof communities === 'string') {
      communitiesList = [communities]
    }
    this.state = {
      [STATE_SORT_ORDER]: parseInt(sortOrder) || DEFAULT_SORT_ORDER,
      [STATE_SORT_COLUMN]: parseInt(sortColumn) || DEFAULT_SORT_COLUMN,
      [STATE_LEAD_STATUS]: (status && getStatusName(status)) || null,
      [STATE_COMMUNITIES]: communitiesList || [],
      [STATE_START_DATE]: startDate || DEFAULT_API_PARAMS.startDate,
      [STATE_END_DATE]: endDate,
      [STATE_TIME_PERIOD_LABEL]: null,
      [STATE_ORGANIZATION_NAME]: null
    }
  }

  static getDerivedStateFromProps(props) {
    const { status, sortOrder, sortColumn, communities, startDate, endDate } =
      props.params || {}
    let communitiesList = communities
    if (typeof communities === 'string') {
      communitiesList = [communities]
    }
    return {
      [STATE_SORT_ORDER]:
        (sortOrder && parseInt(sortOrder)) || DEFAULT_SORT_ORDER,
      [STATE_SORT_COLUMN]:
        (sortColumn && parseInt(sortColumn)) || DEFAULT_SORT_COLUMN,
      [STATE_LEAD_STATUS]: status && getStatusName(status),
      [STATE_COMMUNITIES]: communitiesList || [],
      [STATE_START_DATE]: startDate || DEFAULT_API_PARAMS.startDate,
      [STATE_END_DATE]: endDate || ''
    }
  }

  componentDidMount() {
    const { params, flags } = this.props
    if (params['startDate']) {
      params['startDate'] = getValidStartDate(params['startDate'])
    }
    this.fetchData(params, flags)

    const businessUnits = new Collection(REST_API_ENDPOINTS.businessUnits)
    businessUnits.readSigned(
      {
        params: { depth: 2 }
      },
      (err, res) => {
        if (err) return
        const organizationName = res.data.results[0].nodes
          ? res.data.results[0].nodes[0].name
          : null
        this.setState({ [STATE_ORGANIZATION_NAME]: organizationName })
      }
    )
  }

  componentDidUpdate(prevProps) {
    const { params: prevParams, flags: prevFlags } = prevProps
    const { params, flags } = this.props
    const flagIsnOn = flags.hasOwnProperty(LEAD_BREAKDOWN_COUNT)
      ? flags[LEAD_BREAKDOWN_COUNT]
      : null
    const prevFlagIsOn = prevFlags.hasOwnProperty(LEAD_BREAKDOWN_COUNT)
      ? prevFlags[LEAD_BREAKDOWN_COUNT]
      : null
    if (
      JSON.stringify({ params: prevParams, flag: flagIsnOn }) !==
      JSON.stringify({ params, flag: prevFlagIsOn })
    ) {
      this.fetchData(params, flags)
    }
  }

  fetchData(params, flags) {
    const { fetchCollection } = this.props
    fetchData({ ...params, fetchCollection })
    if (flags && flags[LEAD_BREAKDOWN_COUNT]) {
      fetchBreakdownOfLeadCounts({ ...params, fetchCollection })
    }
  }

  onChangeStatus(status, params) {
    this.goTo({ ...params, status, resetPagination: true })
  }

  updateFilters(shouldResetPagination, communities) {
    const resetPagination = true
    if (shouldResetPagination) {
      this.goTo({ communities, resetPagination })
    }
  }

  goTo({
    sortOrder,
    sortColumn,
    direction,
    page,
    resetPagination,
    communities,
    status,
    startDate,
    endDate
  }) {
    const statusCode = status || this.state[STATE_LEAD_STATUS]
    goToPage(this.props.referrals, {
      sortOrder:
        sortOrder === undefined ? this.state[STATE_SORT_ORDER] : sortOrder,
      sortColumn:
        sortColumn === undefined ? this.state[STATE_SORT_COLUMN] : sortColumn,
      communities: communities || this.state[STATE_COMMUNITIES],
      status: statusCode && statusCode.replace('#status-', ''),
      startDate:
        startDate === undefined ? this.state[STATE_START_DATE] : startDate,
      endDate,
      direction,
      resetPagination,
      page
    })
  }

  render() {
    const prefix = 'leads-'
    const {
      referralsRequestStatus,
      referrals,
      referralsError,
      breakdownCounts,
      breakdownCountsStatus,
      breakdownCountsError,
      params,
      flags
    } = this.props
    const meta = referrals.meta || {}
    const results = referrals.results || []
    const {
      [STATE_SORT_ORDER]: sortOrder,
      [STATE_SORT_COLUMN]: sortColumn,
      [STATE_COMMUNITIES]: selectedCommunities,
      [STATE_LEAD_STATUS]: status,
      [STATE_TIME_PERIOD_LABEL]: timePeriodLabel,
      [STATE_ORGANIZATION_NAME]: organizationName
    } = this.state

    const isFeatureCountEnabled = flags && flags[LEAD_BREAKDOWN_COUNT]
    referralsError && console.warn(referralsError)
    breakdownCountsError && console.warn(breakdownCountsError)
    let breakdownStatuses =
      (breakdownCountsMock && breakdownCountsMock.results) || []
    if (isFeatureCountEnabled) {
      breakdownStatuses = (breakdownCounts && breakdownCounts.results) || []
    }
    const downloadParams = getParamFilters({ ...params })
    downloadParams && delete downloadParams.page
    return (
      <div className="po-r">
        {referralsRequestStatus ? (
          <Loader.Grid className="pat-4" template="Leads" />
        ) : (
          <>
            <div
              className={cx('di-f-p di-n-po alit-c po-a', styles.topActions)}
            >
              <FeatureToggle name="leadsStatusDropdown">
                <div className="di-f juco-fe max-1">
                  <StatusDropdown
                    selected={status ? status.replace('#status-', '') : ''}
                    statuses={breakdownStatuses}
                    loading={breakdownCountsStatus}
                    error={breakdownCountsError}
                    onEventOutside={selectedStatuses => {
                      const statusValue = selectedStatuses.reduce(
                        (acc, item) => acc + item.code,
                        0
                      )
                      const oldStatus = status
                        ? status.replace('#status-', '')
                        : ''
                      if (statusValue !== Number(oldStatus))
                        this.onChangeStatus(statusValue + '', params)
                    }}
                  />
                </div>
              </FeatureToggle>
              <div className="max-1">
                <TimePeriodDropdown
                  preffix={`${prefix}date-filter-`}
                  label={timePeriodLabel}
                  timePeriods={TIME_PERIODS}
                  onChange={({ label, startDate, endDate }) => {
                    this.setState(
                      {
                        [STATE_TIME_PERIOD_LABEL]: label
                      },
                      () =>
                        this.goTo({
                          startDate,
                          endDate,
                          resetPagination: true
                        })
                    )
                  }}
                  initialStartDate={params.startDate}
                  initialEndDate={params.endDate}
                />
              </div>
              <div className="di-f juco-fe max-1">
                <BusinessUnitsDropdown
                  preffix={`${prefix}businessunits-filter-`}
                  onEventOutside={dropDownCommunities => {
                    onFilterByCommunities(
                      selectedCommunities,
                      dropDownCommunities,
                      (shouldUpdate, communities) =>
                        this.updateFilters(shouldUpdate, communities)
                    )
                  }}
                  selectedLeafsIds={selectedCommunities}
                  label="All Communities"
                  multipleLabel="Multiple"
                  depth={4}
                />
              </div>
              <FeatureToggle name="leadsStatsButton">
                <div className="max-1">
                  <Button
                    className={cx(
                      'fosi-2',
                      'alit-c',
                      'pay-2',
                      'qa-leads-button__action--hidestats-leads'
                    )}
                    palette={THEMES.white}
                    id={`${prefix}hide-stats-button`}
                  >
                    Hide Stats
                  </Button>
                </div>
              </FeatureToggle>
              <div className="max-1">
                <Downloader
                  id={`${prefix}export-leads-button`}
                  totalItems={meta.totalItems}
                  params={downloadParams}
                  organizationName={organizationName}
                />
              </div>
            </div>
            <div className="mat-4 pat-2">
              <div className={cx('po-r', 'di-f', styles.statusFilter)}>
                {isFeatureCountEnabled && breakdownCountsError ? (
                  'We were unable to retrieve your leads at this time. Please try again shortly.'
                ) : isFeatureCountEnabled && breakdownCountsStatus ? (
                  <Tabs
                    id="loading-tab"
                    key="loading-tab"
                    initialTabActive="loading-placeholder"
                  >
                    <Tab link="loading-placeholder">Loading...</Tab>
                  </Tabs>
                ) : breakdownStatuses.length === 0 ? (
                  'No data found'
                ) : (
                  <Tabs
                    id="status-tabs"
                    key="status-tabs"
                    className="ovx-a"
                    onTabChange={selected =>
                      this.onChangeStatus(selected, params)
                    }
                    initialTabActive={
                      status ||
                      (breakdownStatuses.length > 0
                        ? `#status-${breakdownStatuses[0].code}`
                        : null)
                    }
                  >
                    {breakdownStatuses.map((item, key) => (
                      <Tab
                        key={key}
                        id={`status-tabs-tab-${Format.snakeSpaces(item.name)}`}
                        link={getStatusName(item.code)}
                        className={cx('whsp-nw', styles.breakdownTab)}
                      >
                        {item.name}
                        {isFeatureCountEnabled && ` (${item.count})`}
                      </Tab>
                    ))}
                  </Tabs>
                )}
              </div>
              <Table
                preffix={prefix}
                sortOrder={sortOrder}
                sortColumn={sortColumn}
                onUpdateSort={(sortColumn, sortOrder) => {
                  if (sortColumn !== null)
                    this.goTo({
                      ...params,
                      sortColumn,
                      sortOrder,
                      resetPagination: true
                    })
                }}
                referrals={results}
              />
            </div>
          </>
        )}

        {!referralsRequestStatus && !referralsError ? (
          <Paginator
            prefix={prefix}
            onClickNext={() =>
              this.goTo({ ...params, direction: PAGINATION_NEXT_PAGE })
            }
            onClickPrev={() =>
              this.goTo({ ...params, direction: PAGINATION_PREV_PAGE })
            }
            onClickStep={step => this.goTo({ ...params, page: step })}
            currentPage={(meta && meta.page) || 1}
            totalPages={
              (meta && Math.ceil(meta.totalItems / meta.itemsPerPage)) || 1
            }
            className="mat-3"
          />
        ) : null}
      </div>
    )
  }
}

List.propTypes = {
  fetchCollection: func,
  referrals: any,
  referralsError: any,
  referralsRequestStatus: bool,
  breakdownCounts: any,
  breakdownCountsError: any,
  breakdownCountsStatus: bool,
  params: object,
  flags: object
}

List.defaultProps = {
  fetchCollection: () => null,
  referrals: {},
  referralsError: null,
  referralsRequestStatus: true,
  breakdownCounts: {},
  breakdownCountsError: null,
  breakdownCountsStatus: true,
  params: {},
  flags: {}
}

const mapStateToProps = createStructuredSelector({
  referrals: selectors.getReferrals(),
  referralsError: selectors.getReferralsRequestError(),
  referralsRequestStatus: selectors.getReferralsRequestStatus(),
  breakdownCounts: selectors.getCollection(
    REST_API_ENDPOINTS.breakdownStatusCounts
  ),
  breakdownCountsError: selectors.getCollectionRequestError(
    REST_API_ENDPOINTS.breakdownStatusCounts
  ),
  breakdownCountsStatus: selectors.getCollectionRequestStatus(
    REST_API_ENDPOINTS.breakdownStatusCounts
  )
})

export default asTogglesConsumer(connect(mapStateToProps, actions)(List))

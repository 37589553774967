import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import cx from 'classnames'
import { func, string, object } from 'prop-types'

// modules
import * as selectors from '@modules/redux/selectors'
import * as actions from '@modules/redux/actions'
import { REST_API_ENDPOINTS } from '@modules/constants'
import { Collection } from '@modules/rest-api'

// Components
import Loader from '@components/Loader'
import MediaObject, { Sidebar, Content } from '@components/MediaObject'
import { Tabs, Tab } from '@components/Tabs'
import DynamicSection from '@components/DynamicSection'
import Details, { Subheader } from '@components/Details'
import Overview from '../Overview'
import ActivityFeed from '../ActivityFeed'
import ReferralAlerts from '../ReferralAlerts'
import SendMessage from '../SendMessage'
import ContactDetails from '../ContactDetails'
import ResidentDetails from '../ResidentDetails'

// styles
import styles from './_.module.scss'

// accessors
const STATE_LEAD = 'lead'
const STATE_REFERRALS = 'referrals'
const STATE_STATUS_REFERRALS = 'referralsRequestStatus'
const DEFAULT_PAYLOAD = { results: { community: {}, advisor: {}, id: null } }
const STATE_ACTIVITY_ID_ADDED = 'activityIdAdded'
const STATE_TEMP_REFERRAL_ACTIVITY = 'tempReferralActivity'
const TYPE_USER = 'USER'
const TEMP_ACTIVITY_TITLE = 'Other'
const STATE_REFERRAL_ALERTS = 'referralAlerts'
const STATE_REFERRAL_ALERTS_STATUS = 'referralAlertsStatus'
const STATE_REFERRAL_ALERTS_ERROR = 'referralAlertsError'

const getCommunityId = communities =>
  (communities && communities.length > 0 && communities[0].id) || null

const getTempProfile = (profile, model) => {
  const today = new Date();
  return {
    type: TYPE_USER,
    createdDateUtc: today,
    updatedDateUtc: today,
    title: TEMP_ACTIVITY_TITLE,
    description: model.text,
    author: { 
      firstName: profile.given_name || 'Loading...',
      lastName: profile.family_name || ''
    }
  }
}

class LeadsDetailsContent extends Component {
  state = {
    [STATE_LEAD]: null,
    [STATE_REFERRALS]: null,
    [STATE_STATUS_REFERRALS]: false,
    [STATE_ACTIVITY_ID_ADDED]: null,
    [STATE_TEMP_REFERRAL_ACTIVITY]: null,
    [STATE_REFERRAL_ALERTS]: []
  }

  componentDidMount() {
    const { id, fetchResource, communityId: queryCommunityId } = this.props
    if (!id) return
    fetchResource(
      REST_API_ENDPOINTS.leads,
      id,
      { apiVersion: 1 },
      (err, res) => {
        if (err) return console.warn(err)
        this.setState({
          [STATE_LEAD]: res.data,
          [STATE_STATUS_REFERRALS]: true
        })
        const { results } = this.state[STATE_LEAD] || {}
        const { communities } = results || {}
        const communityId = queryCommunityId || getCommunityId(communities)
        this.fetchReferral(communityId)
      }
    )

    this.fetchReferralAlerts(queryCommunityId, (err, res) => {
      if(err) return this.setState({
        [STATE_REFERRAL_ALERTS_STATUS]: false,
        [STATE_REFERRAL_ALERTS_ERROR]: true
      })

      this.setState({
        [STATE_REFERRAL_ALERTS_STATUS]: false,
        [STATE_REFERRAL_ALERTS_ERROR]: false,
        [STATE_REFERRAL_ALERTS]: res.data.results
      })
    })
  }

  componentDidUpdate(prevProps) {
    const { id, communityId } = this.props
    if (!id) return
    if (prevProps.communityId !== communityId) {
      this.setState({ [STATE_STATUS_REFERRALS]: true })
      this.fetchReferral(communityId)
    }
  }

  fetchReferral(communityId) {
    const { id, fetchResource } = this.props
    fetchResource(
      REST_API_ENDPOINTS.referrals,
      id,
      { params: { communityId }, apiVersion: 1 },
      (err, res) => {
        if (err) {
          this.setState({ [STATE_STATUS_REFERRALS]: false })
          return console.warn({ referralsError: err })
        }
        this.setState({
          [STATE_REFERRALS]: res.data,
          [STATE_STATUS_REFERRALS]: false
        })
      }
    )
  }

  fetchReferralAlerts(communityId, cb) {
    (new Collection(REST_API_ENDPOINTS.referralAlerts)).readSigned({
      params: {
        communityId,
        leadId: this.props.id,
        sortOrder: 0,
        itemsPerPage: 100
      }
    }, (err, res) => {
      cb && cb(err, res)
    })
  }

  goToTab = link => event => {
    event.preventDefault()
    this.tabsRef.goToTab(link)
  }
  render() {
    const { profile } = this.props;
    const { results: lead } = this.state[STATE_LEAD] || DEFAULT_PAYLOAD
    const { id: leadId, communities, advisor } = lead || DEFAULT_PAYLOAD.results
    // @TODO Refactor code to use controller-presenter pattern
    const { results: referral } = this.state[STATE_REFERRALS] || {
      results: { community: {} }
    }
    const activityIdAdded = this.state[STATE_ACTIVITY_ID_ADDED]
    const isLeadReady = !!leadId
    const isReferralReady =
      !this.state[STATE_STATUS_REFERRALS] && !!referral.community
    const { community } = isReferralReady ? referral : { community: {} }

    const referralAlerts = this.state[STATE_REFERRAL_ALERTS]
    const referralAlertsStatus = this.state[STATE_REFERRAL_ALERTS_STATUS]
    const referralAlertsError = this.state[STATE_REFERRAL_ALERTS_ERROR]
    
    return isLeadReady ? (
      <div className={styles.leadsDetails}>
        <Tabs
          ref={tabsComponent => {
            this.tabsRef = tabsComponent
          }}
          initialTabActive="#overview"
          className={styles.tabs}
          isPhoneOnly
          isFill
          id="leads-details-tabs"
        >
          <Tab link="#overview">Overview</Tab>
          <Tab link="#note">Note</Tab>
          <Tab link="#activity">Activity</Tab>
          <Tab link="#details">Details</Tab>
        </Tabs>

        <MediaObject
          className={styles.leadsMediaObject}
          alignCenter={false}
          float={MediaObject.FLOAT_RIGHT}
        >
          <Content className={cx('par-4', styles.leadsContent)}>
            <div
              id="overview"
              className={cx(
                Tabs.tabContentClass,
                Tabs.activeTabContent,
                Tabs.phoneOnly,
                styles.leadsMobileContainer
              )}
            >
              <Overview
                activeCommunity={community}
                communities={communities}
                leadId={leadId}
                advisor={advisor}
                className={cx(styles.leadsOverview)}
                isLeadReady={isLeadReady}
                isReferralReady={isReferralReady}
              />

              <div
                className={cx(
                  'di-f-po',
                  'di-n-p',
                  'fldi-c',
                  styles.mobileDynamicSections
                )}
              >
                <DynamicSection
                  header="Note from Advisor"
                  onClick={this.goToTab('#note')}
                >
                  {
                    referralAlertsStatus
                    ? 'Loading the referral alerts...'
                    : (referralAlertsError ? 'We could not load the referral alerts. Please try again.' : <ReferralAlerts referralAlerts={referralAlerts} />)
                  }
                </DynamicSection>
                
                <DynamicSection
                  header="Recent Activity"
                  onClick={this.goToTab('#activity')}
                >
                  <div className="mat-2">
                   {isLeadReady &&
                      (isReferralReady ? (
                        <ActivityFeed
                          leadId={leadId}
                          communityId={community.id}
                          isMobileOnly
                        />
                      ) : (
                        'loading...'
                      ))}
                  </div>
                </DynamicSection>
                
                <DynamicSection
                  header="Details"
                  onClick={this.goToTab('#details')}
                >
                  <div className="di-f fldi-c pay-4">
                    {isLeadReady && (
                      <>
                        <ContactDetails
                          contact={lead.contact}
                          isMobileOnly
                          isResume
                        />
                        <ResidentDetails
                          resident={lead.resident}
                          isMobileOnly
                          isResume
                        />
                      </>
                    )}
                  </div>
                </DynamicSection>
              </div>
            </div>

            <Tabs
              id="details-tabs"
              ref={tabsComponent => {
                this.tabsRef = tabsComponent
              }}
              initialTabActive="#note"
              className={cx(styles.tabs, styles.desktopTabs)}
            >
              <Tab link="#note">Notes From Advisor</Tab>
              <Tab link="#activity">Activity</Tab>
            </Tabs>

            <div
              id="note"
              className={cx(
                Tabs.tabContentClass,
                Tabs.activeTabContent,
                styles.leadsMobileContainer
              )}
            >
              {
                referralAlertsStatus
                ? 'Loading the referral alerts...'
                : (referralAlertsError ? 'We could not load the referral alerts. Please try again.' : <ReferralAlerts referralAlerts={referralAlerts} />)
              }
            </div>

            <div
              id="activity"
              className={cx(
                Tabs.tabContentClass,
                styles.leadsMobileContainer
              )}
            >
              {isLeadReady && (
                isReferralReady ? (
                  <ActivityFeed
                    leadId={leadId}
                    communityId={community.id}
                    activityIdAdded={activityIdAdded}
                    tempReferralActivities={this.state[STATE_TEMP_REFERRAL_ACTIVITY]}
                    onFetchDataDone={() => this.setState({ [STATE_TEMP_REFERRAL_ACTIVITY]: null })}
                  />
                ) : (
                  'loading...'
                )
              )}
            </div>
            <SendMessage
              name="message"
              leadId={leadId}
              communityId={community.id}
              onSendMessage={(resp, model) =>
                resp &&
                resp.results &&
                this.setState(() => {
                  return {
                    [STATE_ACTIVITY_ID_ADDED]: resp.results,
                    [STATE_TEMP_REFERRAL_ACTIVITY]: getTempProfile(profile, model)
                  }
                })
              }
            />
          </Content>
          <Sidebar width="" className={cx(styles.leadsSidebar)}>
            <div
              id="details"
              className={cx(
                Tabs.tabContentClass,
                Tabs.phoneOnly,
                styles.leadsMobileContainer
              )}
            >
              <Details className="di-f-p di-n-po">
                <Subheader>Primary Contact</Subheader>
                {lead && <ContactDetails contact={lead.contact} />}

                <Subheader>Resident Profile</Subheader>
                {lead && <ResidentDetails resident={lead.resident} />}
              </Details>

              <Details className="di-f-po di-n-p">
                <Subheader>Primary Contact</Subheader>
                {lead && <ContactDetails contact={lead.contact} isMobileOnly />}

                <Subheader>Resident Profile</Subheader>
                {lead && (
                  <ResidentDetails resident={lead.resident} isMobileOnly />
                )}
              </Details>
            </div>
          </Sidebar>
        </MediaObject>
      </div>
    ) : (
      <>
        <Loader.Paragraph
          figure="none"
          width="360"
          height="100"
          align="right"
        />
        <Loader.Paragraph
          figure="none"
          width="360"
          height="100"
          align="right"
        />
      </>
    )
  }
}

LeadsDetailsContent.propTypes = {
  id: string.isRequired,
  profile: object,
  communityId: string,
  fetchResource: func
}

LeadsDetailsContent.defaultProps = {
  fetchResource: () => null,
  profile: null,
  communityId: ''
}

const mapStateToProps = createStructuredSelector({
  profile: selectors.getProfile()
})

export default connect(
  mapStateToProps,
  actions
)(LeadsDetailsContent)

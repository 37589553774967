import React from 'react'
import cx from 'classnames'
import { node, string } from 'prop-types'

const ListItem = ({ className, children }) => (
  <li>
    <div className={cx('di-f', 'alit-c', 'juco-sb', className)}>
      {children}
    </div>
  </li>
)

ListItem.propTypes = {
  children: node,
  className: string
}

ListItem.defaultProps = {
  children: null,
  className: ''
}

export default ListItem

export const SORT_DESC = 0
export const SORT_ASC = 1
export const SORTBY_DATE_REFERRED = 0
export const SORTBY_CONTACT = 1
export const SORTBY_RESIDENT = 2
export const SORTBY_ADVISOR = 3
export const SORTBY_COMMUNITY_STATUS = 4
export const SORTBY_COMMUNITY = 5
export const SORTBY_LEAD_ID = 6
export const SORTBY_COMMUNITY_TOUR = 7

export const COLUMNS = [
  {
    sortBy: SORTBY_DATE_REFERRED,
    text: 'Date Referred',
    classNamePointer: 'date'
  },
  { sortBy: SORTBY_CONTACT, text: 'Contact', classNamePointer: 'contact' },
  { sortBy: SORTBY_RESIDENT, text: 'Resident', classNamePointer: 'resident' },
  { sortBy: SORTBY_ADVISOR, text: 'Advisor', classNamePointer: 'advisor' },
  {
    sortBy: SORTBY_COMMUNITY,
    text: 'Community',
    classNamePointer: 'community'
  },
  {
    sortBy: SORTBY_COMMUNITY_STATUS,
    text: 'Status',
    classNamePointer: 'status'
  },
  {
    sortBy: SORTBY_COMMUNITY_TOUR,
    text: 'Tour Date',
    classNamePointer: 'tour'
  },
  {
    sortBy: SORTBY_LEAD_ID,
    text: 'Lead ID',
    classNamePointer: 'leadId'
  }
]

import React, { useState, useEffect } from 'react'
import { number, string, any } from 'prop-types'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'

// components
import HtmlParser from '@components/HtmlParser'
import Icon from '@components/Icon'

const Clamper = ({icon, children, ...rest}) => <div {...rest} className="teco-blue-1 fowe-b cu-p mat-2">{children} {icon}</div>
Clamper.propTypes = {
  icon: any.isRequired,
  children: any.isRequired
}

const LinesBreaker = ({maxLine, content}) => {
  const [isClamped, setIsClamped] = useState(true)
  const [checkClamp, setCheckClamp] = useState(false)
  const [needsClamper, setNeedsClamper] = useState(false)

  useEffect(() => {
    setCheckClamp(false)
  }, [maxLine, content])

  return (
    <div>
      <article
        onClick={() => needsClamper && setIsClamped(!isClamped)}
      >
        {
          isClamped 
          ? (
            <HTMLEllipsis 
              maxLine={maxLine} 
              unsafeHTML={content}
              basedOn="letters"
              onReflow={({clamped}) => {
                if(!checkClamp) {
                  setNeedsClamper(clamped)
                  setCheckClamp(true)
                }
              }}
            />
          )
          : <HtmlParser content={content} />
        }
      </article>

      {needsClamper && (
          <Clamper
            onClick={() => setIsClamped(!isClamped)}
            icon={<Icon name={isClamped ? 'chevron-down' : 'chevron-up'} className="di-i pal-1" size="0.7rem" />}
          >
            {isClamped ? 'Read more' : 'Read less'}
          </Clamper>
        )
      }
    </div>
  )
}

LinesBreaker.propTypes = {
  content: string.isRequired,
  maxLine: number
}

LinesBreaker.defaultProps = {
  maxLine: 1
}

export default LinesBreaker
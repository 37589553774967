import React from 'react'
import cx from 'classnames'
import { node, string, number, func, bool, oneOf } from 'prop-types'

// components
import Icon from '@components/Icon'
import { THEMES } from '@components/constants'

// modules
import Formatter from '@modules/formatter'

// hoc
import withPalette from '@hoc/withPalette'

// styles
import styles from './_.module.scss'
import { ARROW_RIGHT, ARROW_DOWN, ARROW_LEFT } from '../../modules/constants'

const Item = ({
  children,
  onClick,
  icon,
  total,
  palette,
  className,
  contentClassName,
  role,
  hideArrow,
  arrowDirection,
  id
}) => {
  const paletteColor = Formatter.ucfirst(palette)
  const paletteClassName =
    (paletteColor && styles[`item${paletteColor}`]) || null
  return (
    <div
      className={cx(
        'di-f',
        'juco-sb',
        'alit-c',
        'pax-4',
        'pay-2',
        'cu-p',
        {
          [paletteClassName]: palette && paletteClassName
        },
        styles.item,
        className
      )}
      onClick={onClick}
      role={role}
      id={id}
    >
      {icon && (
        <div className={cx('di-f', 'par-2', styles.itemIcon)}>{icon}</div>
      )}
      <div className={cx('di-f', contentClassName, styles.itemText)}>
        {children}
      </div>
      {!(hideArrow && total === null) && (
        <div className="di-f co-gray-4 fowe-n">
          {total}
          {!hideArrow && (
            <Icon
              className={cx('pal-2', styles.itemChevron)}
              name={arrowDirection}
              size={arrowDirection === ARROW_DOWN ? 10 : 20}
            />
          )}
        </div>
      )}
    </div>
  )
}

Item.propTypes = {
  children: node,
  id: string,
  className: string,
  contentClassName: string,
  palette: string,
  icon: node,
  total: number,
  role: string,
  hideArrow: bool,
  arrowDirection: oneOf([ARROW_RIGHT, ARROW_LEFT, ARROW_DOWN]),
  onClick: func
}

Item.defaultProps = {
  id: null,
  children: null,
  palette: THEMES.black,
  className: null,
  contentClassName: null,
  icon: null,
  total: null,
  role: null,
  hideArrow: false,
  arrowDirection: ARROW_RIGHT,
  onClick: () => null
}

export default withPalette(Item)

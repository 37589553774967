import React from 'react'
import cx from 'classnames'
import { string, node, oneOf } from 'prop-types'

const ALIGN_HORIZONTAL = 'horizontal'
const ALIGN_VERTICAL = 'vertical'

const Item = ({ attribute, align, children, className, ...rest }) => (
  <div
    className={cx(
      'di-f',
      align === ALIGN_HORIZONTAL ? 'fldi-r juco-sb' : 'fldi-c',
      'pat-2',
      'fosi-2',
      className
    )}
    {...rest}
  >
    {attribute && <b>{attribute}</b>}
    <div className="co-gray-4">{children}</div>
  </div>
)

Item.propTypes = {
  align: oneOf([ALIGN_HORIZONTAL, ALIGN_VERTICAL]),
  attribute: string,
  children: node,
  className: string
}

Item.defaultProps = {
  align: ALIGN_VERTICAL,
  attribute: '',
  children: null,
  className: ''
}

Item.ALIGN_HORIZONTAL = ALIGN_HORIZONTAL
Item.ALIGN_VERTICAL = ALIGN_VERTICAL

export default Item

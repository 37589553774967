import React, { Component } from 'react'
import { any, func, array, bool, number, object, string } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

// modules
import * as selectors from '@modules/redux/selectors'
import * as actions from '@modules/redux/actions'

// components
import Dropdown from '@components/Dropdown'
import BusinessUnits from '@components/BusinessUnits'
import Handler from './components/Handler'

// modules
import { REST_API_ENDPOINTS } from '@modules/constants'

// accessors
const STATE_SELECTED_BRANCHES_IDS = 'selectedBranchesIds'
const STATE_SELECTED_LEAFS_IDS = 'selectedLeafsIds'
const STATE_SELECTED_BRANCHES_IDS_PARTIALLY = 'selectedBranchesIdsPartially'
class BusinessUnitsDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      [STATE_SELECTED_BRANCHES_IDS]: new Set(this.props.selectedBranchesIds),
      [STATE_SELECTED_LEAFS_IDS]: new Set(this.props.selectedLeafsIds)
    }
  }

  componentDidMount() {
    const { fetchCollection } = this.props
    const config = {
      apiVersion: 1
    }
    fetchCollection(REST_API_ENDPOINTS.businessUnits, config)
  }

  render() {
    const {
      [STATE_SELECTED_BRANCHES_IDS_PARTIALLY]: selectedBranchesIdsPartially,
      [STATE_SELECTED_BRANCHES_IDS]: selectedBranchesIds,
      [STATE_SELECTED_LEAFS_IDS]: selectedLeafsIds
    } = this.state

    const selectedBranchesIdsAsArray = Array.from(selectedBranchesIds)
    const selectedLeafsIdsAsArray = Array.from(selectedLeafsIds)

    const {
      preffix,
      response,
      onEventOutside,
      responseRequestStatus,
      responseRequestError,
      label,
      multipleLabel,
      depth,
      contentWrapperStyle
    } = this.props

    const { results } = response || { results: [] }
    responseRequestError && console.warn(responseRequestError)

    return (
      <Dropdown
        hideOnEventName="mousedown"
        onEventOutside={() =>
          onEventOutside(selectedLeafsIdsAsArray, selectedBranchesIdsAsArray)
        }
        handler={isOpen => (
          <Handler
            id={`${preffix}dropdown-open`}
            isOpen={isOpen}
            active={'activeCommunities'}
            total={selectedLeafsIdsAsArray.length}
            label={label}
            multipleLabel={multipleLabel || label}
          />
        )}
        isOpen={false}
        contentWrapperStyle={contentWrapperStyle}
        cardStyle={{ overflowY: 'auto' }}
      >
        <BusinessUnits
          onChange={({ branches, leafsIds }) => {
            const changes = {
              [STATE_SELECTED_LEAFS_IDS]: leafsIds
            }
            if (branches) {
              changes[STATE_SELECTED_BRANCHES_IDS] = branches
            }
            this.setState(changes)
          }}
          onEventOutside={onEventOutside}
          selectedLeafsIdsAsArray={selectedLeafsIdsAsArray}
          selectedBranchesIdsAsArray={selectedBranchesIdsAsArray}
          label={label}
          responseRequestStatus={responseRequestStatus}
          responseRequestError={responseRequestError}
          depth={depth}
          selectedBranchesIds={selectedBranchesIds}
          selectedLeafsIds={selectedLeafsIds}
          results={results}
          selectedBranchesIdsPartially={selectedBranchesIdsPartially}
        />
      </Dropdown>
    )
  }
}

BusinessUnitsDropdown.propTypes = {
  preffix: string,
  contentWrapperStyle: object,
  depth: number,
  fetchCollection: func,
  label: any,
  multipleLabel: any,
  response: any,
  responseRequestError: any,
  responseRequestStatus: bool,
  selectedBranchesIds: array,
  selectedLeafsIds: array,
  onEventOutside: func
}

BusinessUnitsDropdown.defaultProps = {
  preffix: null,
  depth: 4,
  fetchCollection: () => null,
  label: '__unset__',
  multipleLabel: null,
  onEventOutside: () => null,
  response: {},
  responseRequestError: null,
  responseRequestStatus: false,
  selectedLeafsIds: [],
  selectedBranchesIds: [],
  contentWrapperStyle: {}
}

const mapStateToProps = createStructuredSelector({
  response: selectors.getCollection(REST_API_ENDPOINTS.businessUnits),
  responseRequestStatus: selectors.getCollectionRequestStatus(
    REST_API_ENDPOINTS.businessUnits
  ),
  responseRequestError: selectors.getCollectionRequestError(
    REST_API_ENDPOINTS.businessUnits
  )
})

export default connect(
  mapStateToProps,
  actions
)(BusinessUnitsDropdown)

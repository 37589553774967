import { ALL_CODE } from '../constants'

export const getActiveStatusesFromUrl = (binary, statuses) => {
  if (!binary) return new Map()
  const cloneStatuses = statuses.slice()
  return new Map(
    cloneStatuses
      .filter(item => {
        if (item.code === ALL_CODE) return false
        const binaryCompare = binary & item.code
        if (binaryCompare) {
          return true
        }
        return false
      })
      .map(item => [item.code, item])
  )
}

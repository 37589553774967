import React, { Component } from 'react'
import { node, string } from 'prop-types'
import cx from 'classnames'

// styles
import styles from './_.module.scss'

export default class ChipGroup extends Component {
  render() {
    const { children, className } = this.props

    return (
      <div className={cx(styles.chipGroup, 'di-f', className)}>{children}</div>
    )
  }
}

ChipGroup.propTypes = {
  children: node,
  className: string
}

ChipGroup.defaultProps = {
  children: null,
  className: ''
}

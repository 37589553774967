import React, { Component } from 'react'
import cx from 'classnames'
import { number, object, string } from 'prop-types'

// modules
import { Collection } from '@modules/rest-api'
import { REST_API_ENDPOINTS, MONTHS } from '@modules/constants'
import Env from '@modules/env'

// components
import Button from '@components/Button'
import { THEMES } from '@components/constants'

// helpers
const handleFileDownload = (params = {}, filename, cb = () => null) => {
  const collection = new Collection(REST_API_ENDPOINTS.referrals, 1)

  collection.readSignedSpreadsheet({ params }, (err, res) => {
    if (err) return cb(err)

    const url = Env.getWindow().URL.createObjectURL(new Blob([res.data]))
    const link = Env.getDocument().createElement('a')
    link.href = url
    link.setAttribute('download', `${filename || 'leads'}.xlsx`)
    Env.getDocument().body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)

    cb(null)
  })
}

const constructFileName = organizationName => {
  const date = new Date()
  const monthAsNumber = date.getMonth()
  const fullYear = date.getFullYear()

  return organizationName
    ? `${organizationName} ${MONTHS[monthAsNumber]} ${fullYear}`
    : 'undefined-org'
}

const STATE_REQUEST_STATUS = 'requestStatus'
const STATE_REQUEST_ERROR = 'requestError'

export default class Downloader extends Component {
  state = {
    [STATE_REQUEST_STATUS]: false,
    [STATE_REQUEST_ERROR]: null
  }

  render() {
    const { id, totalItems, organizationName, params } = this.props
    const { [STATE_REQUEST_STATUS]: requestStatus } = this.state
    const constructedFileName = constructFileName(organizationName)

    return (
      <Button
        id={id}
        className={cx(
          'fosi-2',
          'pay-2',
          'qa-leads-button__action--export-leads'
        )}
        palette={THEMES.primary}
        onClick={() => {
          this.setState({
            [STATE_REQUEST_STATUS]: true,
            [STATE_REQUEST_ERROR]: null
          })

          handleFileDownload(
            {
              ...params,
              itemsPerPage: totalItems,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            constructedFileName,
            (err, res) => {
              if (err) alert('Unable to download leads.')

              this.setState({
                [STATE_REQUEST_STATUS]: false,
                [STATE_REQUEST_ERROR]: err
              })
            }
          )
        }}
        disabled={!totalItems || requestStatus}
      >
        {requestStatus ? 'Processing...' : 'Export View'}
      </Button>
    )
  }
}

Downloader.propTypes = {
  id: string,
  params: object,
  totalItems: number,
  organizationName: string
}

Downloader.defaultProps = {
  id: null,
  totalItems: 50,
  params: {},
  organizationName: null
}

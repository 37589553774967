import React from 'react'
import cx from 'classnames'
import { node, string } from 'prop-types'

const Details = ({ children, className }) => (
  <div className={cx('di-f', 'fldi-c', className)}>{children}</div>
)

Details.propTypes = {
  children: node,
  className: string
}

Details.defaultProps = {
  children: null,
  className: null
}

export default Details

import React from 'react'
import cx from 'classnames'
import { any, array, shape, func, number, string } from 'prop-types'

// components
import ChipGroup from '@components/ChipGroup'
import Label from '@components/Label'
import Icon from '@components/Icon'

// modules
import Formatter from '@modules/formatter'
import Copy from '@modules/copy'

// styles
import styles from './_.module.scss'

import {
  SORT_ASC,
  SORTBY_DATE_REFERRED,
  COLUMNS,
  SORT_DESC
} from '../constants'

const classes = {
  ...styles,
  dateContact: cx(styles.dateContact, 'fldi-c'),
  communityStatus: cx(styles.communityStatus, 'fldi-c'),
  tourLeadId: cx(styles.tourLeadId, 'fldi-c'),
  residentAdvisor: cx(styles.residentAdvisor, 'fldi-c'),
  wrapper: cx('di-f', 'fldi-r', styles.wrapper),
  community: cx(styles.community, 'ov-h', 'whsp-nw', 'teov-e'),
  columnHeaders: cx('di-f-p', 'di-n-po'),
  columnHeader: cx('fosi-1', 'fowe-b', 'di-f', 'cu-p'),
  contact: cx(styles.contact, 'fowe-b', 'co-black')
}

const renderLabelStatus = status => {
  const key = generateKey(status, 'chip')
  return (
    <Label
      key={key}
      size={2}
      theme="gray"
      themeLevel={0}
      className={cx('co-gray-4', 'pay-1')}
    >
      {status}
    </Label>
  )
}

const generateKey = (id, text) =>
  `${id}-${text
    .toLowerCase()
    .split(' ')
    .join('')}-${Math.round(Math.random() * 1000)}`

const NoResults = () => <div className="ma-2">{Copy.HTTP_204}</div>

const SortingArrow = ({ sortOrder }) => (
  <Icon
    style={sortOrder === SORT_ASC ? { transform: 'rotate(180deg)' } : {}}
    name="arrow-up"
    size={8}
  />
)

SortingArrow.propTypes = {
  sortOrder: number
}

SortingArrow.defaultProps = {
  sortOrder: 1
}

const ColumnShape = shape({
  sortBy: number,
  text: string,
  classNamePointer: string
})

const SyntheticColumnHeader = ({
  children,
  onClick,
  sortColumn,
  sortOrder,
  className,
  column
}) => {
  let sortOrderInverse = sortOrder
  if (column.sortBy === sortColumn) {
    sortOrderInverse = sortOrder === SORT_ASC ? SORT_DESC : SORT_ASC
  }
  return (
    <div
      onClick={() => onClick(column.sortBy, sortOrderInverse)}
      className={cx(
        classes.column,
        classes.columnHeader,
        classes[column.classNamePointer] || '',
        className
      )}
      data-qid={column.classNamePointer.toLowerCase()}
      role="columnheader"
    >
      {column.text} {children}
      {sortColumn === column.sortBy && <SortingArrow sortOrder={sortOrder} />}
    </div>
  )
}

SyntheticColumnHeader.propTypes = {
  column: ColumnShape.isRequired,
  children: any,
  className: string,
  sortColumn: number,
  sortOrder: number,
  onClick: func
}

SyntheticColumnHeader.defaultProps = {
  onClick: () => null,
  className: '',
  children: null,
  sortBy: 1,
  sortColumn: 1,
  sortOrder: 1,
  column: {}
}

const Table = ({ prefix, referrals, sortOrder, sortColumn, onUpdateSort }) => {
  return (
    <div
      className={cx(classes.tableContainer, 'mal-a', 'mar-a', 'qa-table')}
      role="table"
    >
      <div
        className={cx(
          'di-f',
          'fldi-r',
          'flwr-nw',
          'pax-4',
          'pay-2',
          classes.tableRow,
          classes.columnHeaders,
          'qa-table-headers'
        )}
        role="row"
      >
        <div className={cx(classes.wrapper, classes.dateContact, 'date-contact')}>
          {COLUMNS.slice(0, 2).map((column, index) => (
            <SyntheticColumnHeader
              onClick={onUpdateSort}
              sortColumn={sortColumn}
              sortOrder={sortOrder}
              key={index}
              column={column}
            />
          ))}
        </div>

        <div className={cx(classes.wrapper, classes.residentAdvisor, 'resident-advisor')}>
          {COLUMNS.slice(2, 4).map((column, index) => (
            <SyntheticColumnHeader
              onClick={onUpdateSort}
              sortColumn={sortColumn}
              sortOrder={sortOrder}
              key={index}
              column={column}
            />
          ))}
        </div>

        <div className={cx(classes.wrapper, classes.communityStatus, 'community-status')}>
          {COLUMNS.slice(4, 6).map((column, index) => (
            <SyntheticColumnHeader
              onClick={onUpdateSort}
              sortColumn={sortColumn}
              sortOrder={sortOrder}
              key={index}
              column={column}
            />
          ))}
        </div>
        <div className={cx(classes.wrapper, classes.tourLeadId, 'tour-leadid')}>
          {COLUMNS.slice(6, 8).map((column, index) => (
            <SyntheticColumnHeader
              onClick={onUpdateSort}
              sortColumn={sortColumn}
              sortOrder={sortOrder}
              key={index}
              column={column}
            />
          ))}
        </div>
      </div>

      {referrals && referrals.length > 0 ? (
        referrals.map(referral => {
          const { community, lead, referralStatus } = referral
          const url = `/leads/${lead.leadId}?communityId=${community.id}`
          const statuses = referralStatus.split('-').map(word => word.trim())
          const isActive = statuses.indexOf('Active') !== -1
          const key = `${lead.leadId}-${community.id}`

          return (
            <a
              key={key}
              href={url}
              className={cx(
                'di-f',
                'fldi-r',
                'flwr-nw',
                'fosi-2',
                'pax-4',
                'pay-2',
                'cu-p',
                {
                  [styles.active]: isActive
                },
                classes.tableRow
              )}
              role="row"
            >
              <div className={cx(classes.wrapper, classes.dateContact, 'date-contact')}>
                <div className={cx(classes.column, classes.date, 'date')} role="cell">
                  {Formatter.date(referral.referredDate, 'MM/DD/YYYY h:mma')}
                </div>
                <div
                  className={cx(classes.column, classes.contact, 'contact')}
                  role="cell"
                >
                  {lead.contact.firstName} {lead.contact.lastName}
                </div>
              </div>
              <div className={cx(classes.wrapper, classes.residentAdvisor, 'resident-advisor')}>
                <div
                  className={cx(classes.column, classes.resident, 'resident')}
                  role="cell"
                >
                  {lead.resident.firstName} {lead.resident.lastName}
                </div>
                <div
                  className={cx(classes.column, classes.advisor, 'advisor')}
                  role="cell"
                >
                  {lead.advisor.firstName} {lead.advisor.lastName}
                </div>
              </div>
              <div className={cx(classes.wrapper, classes.communityStatus, 'community-status')}>
                <div
                  className={cx(classes.column, classes.community, 'community')}
                  role="cell"
                >
                  {community.name}
                </div>
                <div className={cx(classes.column, classes.status, 'status')} role="cell">
                  <ChipGroup className={'flwr-w'}>
                    {statuses
                      .filter(status => status !== 'Active')
                      .map(status => renderLabelStatus(status))}
                  </ChipGroup>
                </div>
              </div>
              <div className={cx(classes.wrapper, classes.tourLeadId, 'tour-leadid')}>
                <div className={cx(classes.column, classes.tour, 'tour')} role="cell">
                  {referral.scheduledTourDate
                    ? Formatter.date(
                        referral.scheduledTourDate,
                        'MM/DD/YYYY h:mma'
                      )
                    : 'N/A'}
                </div>
                <div className={cx(classes.column, classes.leadId, 'leadid')} role="cell">
                  {lead.leadId}
                </div>
              </div>
            </a>
          )
        })
      ) : (
        <NoResults />
      )}
    </div>
  )
}

Table.propTypes = {
  prefix: string,
  referrals: array,
  sortOrder: number,
  sortColumn: number,
  onUpdateSort: func
}

Table.defaultProps = {
  prefix: null,
  referrals: [],
  sortOrder: SORT_ASC,
  sortColumn: SORTBY_DATE_REFERRED,
  onUpdateSort: () => null
}

export default Table

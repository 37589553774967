import React, { Component } from 'react'
import cx from 'classnames'
import { bool, any } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

// modules
import * as actions from '@modules/redux/actions'

// components
import { Subheader, Item } from '@components/Details'
import Tag from '@compositions/Tag'
import DisposableItem from '../DisposableItem'

// constants
const QA_PREFIX = 'qa-resident-details'
const QA_PREFIX_OUTPUT = `${QA_PREFIX}__output`
const QA_PREFIX_OUTPUT_RESIDENT = `${QA_PREFIX_OUTPUT}--resident`

class ResidentDetails extends Component {
  render() {
    const { isMobileOnly, isResume, resident } = this.props

    return (
      <>
        {isMobileOnly ? (
          <>
            <DisposableItem.Decorated
              attribute="Resident Name"
              className={`${QA_PREFIX_OUTPUT_RESIDENT}-name`}
            >
              {resident.firstName} {resident.lastName}
            </DisposableItem.Decorated>
            <DisposableItem.Decorated
              attribute="Budget"
              className={`${QA_PREFIX_OUTPUT_RESIDENT}-budget`}
            >
              {resident.budget}
            </DisposableItem.Decorated>
            {!isResume && (
              <>
                <DisposableItem.Decorated
                  attribute="Age"
                  className={`${QA_PREFIX_OUTPUT_RESIDENT}-age`}
                >
                  {resident.age}
                </DisposableItem.Decorated>
                <DisposableItem.Decorated
                  attribute="Desired Location"
                  className={`${QA_PREFIX_OUTPUT_RESIDENT}-desired-location`}
                >
                  {resident.desiredLocation}
                </DisposableItem.Decorated>
                <DisposableItem.Decorated
                  attribute="Address"
                  className={`${QA_PREFIX_OUTPUT_RESIDENT}-address`}
                >
                  {resident.address}
                </DisposableItem.Decorated>
                <DisposableItem.Decorated
                  attribute="Marital Status"
                  className={`${QA_PREFIX_OUTPUT_RESIDENT}-marital-status`}
                >
                  {resident.maritalStatus}
                </DisposableItem.Decorated>
                <DisposableItem.Decorated
                  attribute="Funding Type"
                  className={`${QA_PREFIX_OUTPUT_RESIDENT}-funding-type`}
                >
                  {resident.fundingType}
                </DisposableItem.Decorated>
                <DisposableItem.Decorated
                  attribute="Living Situation"
                  className={`${QA_PREFIX_OUTPUT_RESIDENT}-living-situation`}
                >
                  {resident.livingSituation}
                </DisposableItem.Decorated>
              </>
            )}
          </>
        ) : (
          <>
            <DisposableItem
              attribute="Name"
              className={`${QA_PREFIX_OUTPUT_RESIDENT}-name`}
            >
              {resident.firstName} {resident.lastName}
            </DisposableItem>
            <DisposableItem
              attribute="Budget"
              className={`${QA_PREFIX_OUTPUT_RESIDENT}-budget`}
            >
              {resident.budget}
            </DisposableItem>
            <DisposableItem
              attribute="Age"
              className={`${QA_PREFIX_OUTPUT_RESIDENT}-age`}
            >
              {resident.age}
            </DisposableItem>
            <DisposableItem
              attribute="Desired Location"
              className={`${QA_PREFIX_OUTPUT_RESIDENT}-desired-location`}
            >
              {resident.desiredLocation}
            </DisposableItem>
            <DisposableItem
              attribute="Address"
              className={`${QA_PREFIX_OUTPUT_RESIDENT}-address`}
            >
              {resident.address}
            </DisposableItem>
            <DisposableItem
              attribute="Marital Status"
              className={`${QA_PREFIX_OUTPUT_RESIDENT}-marital-status`}
            >
              {resident.maritalStatus}
            </DisposableItem>
            <DisposableItem
              attribute="Funding Type"
              className={`${QA_PREFIX_OUTPUT_RESIDENT}-funding-type`}
            >
              {resident.fundingType}
            </DisposableItem>
            <DisposableItem
              attribute="Living Situation"
              className={`${QA_PREFIX_OUTPUT_RESIDENT}-living-situation`}
            >
              {resident.livingSituation}
            </DisposableItem>
          </>
        )}
        <Subheader>Assistance Levels</Subheader>
        <Item>
          <div className={cx('di-f', 'fldi-r', 'juco-s', 'flwr-w', 'pat-2')}>
            {resident.assistanceLevels &&
              resident.assistanceLevels.map((tagInfo, key) => (
                <Tag key={key}>{tagInfo}</Tag>
              ))}
          </div>
        </Item>
      </>
    )
  }
}

ResidentDetails.propTypes = {
  isMobileOnly: bool,
  isResume: bool,
  resident: any
}

ResidentDetails.defaultProps = {
  isMobileOnly: false,
  isResume: false,
  resident: {}
}

const mapStateToProps = createStructuredSelector({})

export default connect(
  mapStateToProps,
  actions
)(ResidentDetails)

import React, { Component } from 'react'
import { string, bool, any, func, number } from 'prop-types'
import cx from 'classnames'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

// modules
import * as selectors from '@modules/redux/selectors'
import * as actions from '@modules/redux/actions'
import { REST_API_ENDPOINTS, API_VERSION } from '@modules/constants'
import Formatter from '@modules/formatter'

// components
import AdvisorCard from '@components/AdvisorCard'
import ScrollableContainer from '@components/ScrollableContainer'

// constants
const QA_PREFIX = 'qa-activity-feed'
const TYPE_USER = 'USER'
// TODO: Implement the Interface Segregation Principle in here.
// functions of methods that start with 'renderSomething' are a sign of complexity.
// Implement the Interface Segregation Principle here in order to make
// this more flexible and maintainable.
const displayDate = date => Formatter.date(date)
const renderFeed = (collection = []) => {
  return collection.map((item, key) => {
    const { author, type } = item
    return type === TYPE_USER && author ? (
      <AdvisorCard
        key={key}
        displayTitle={false}
        avatarConfig={{ containerWidth: 'auto', className: 'bora-round' }}
        config={{
          advisorNameColor: 'co-black'
        }}
        name={`${author.firstName} ${author.lastName}`}
        date={displayDate(
          item.createdDateUtc !== item.updatedDateUtc
            ? item.updatedDateUtc
            : item.createdDateUtc
        )}
        content={
          <span className="di-f fldi-c">
            {item.title !== 'Other' && item.title}
            {item.description && (
              <span className="co-gray-4">{item.description}</span>
            )}
          </span>
        }
      />
    ) : item.title ? (
      <AdvisorCard
        key={key}
        displayTitle={false}
        name={item.title}
        date={displayDate(
          item.createdDateUtc !== item.updatedDateUtc
            ? item.updatedDateUtc
            : item.createdDateUtc
        )}
      />
    ) : (
      <div key={key} className={cx('di-f', 'teal-l', 'pa-2', 'fosi-1')}>
        <span className="par-2 di-f fldi-c">{item.description}</span>
        <span>
          {displayDate(
            item.createdDateUtc !== item.updatedDateUtc
              ? item.updatedDateUtc
              : item.createdDateUtc
          )}
        </span>
      </div>
    )
  })
}

class ActivityFeed extends Component {
  componentDidMount() {
    this.fetchData(this.props.communityId)
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.communityId !== this.props.communityId ||
      prevProps.activityIdAdded !== this.props.activityIdAdded
    ) {
      this.fetchData(this.props.communityId)
    }
  }
  fetchData(communityId) {
    const { leadId, fetchCollection } = this.props
    fetchCollection(REST_API_ENDPOINTS.referralActivities, {
      apiVersion: API_VERSION,
      params: {
        leadId,
        communityId
      }
    }, (err, data) => {
      this.props.onFetchDataDone()

      if(err) {
        this.props.setCollectionRequestError(REST_API_ENDPOINTS.referralActivities, err)
        return
      }

      this.props.setCollection(REST_API_ENDPOINTS.referralActivities, data)
    })
  }
  render() {
    const {
      className,
      isMobileOnly,
      tempReferralActivities,
      referralActivities,
      referralActivitiesRequestError,
      referralActivitiesRequestStatus
    } = this.props

    const results = tempReferralActivities
      ? referralActivities && referralActivities.results
        ? [tempReferralActivities, ...referralActivities.results]
        : []
      : referralActivities && referralActivities.results
          ? referralActivities.results
          : []
    const resultsWhenMobile = results.slice(-2)
    const resultsWhenDesktop = results.slice(0)

    return (
      <>
        {referralActivitiesRequestStatus && 'loading...'}
        {referralActivitiesRequestError &&
          'There was an error loading activities...'}

        {!referralActivitiesRequestStatus && !referralActivitiesRequestError ? (
          isMobileOnly ? (
            renderFeed(resultsWhenMobile)
          ) : (
            <div className={cx('pab-4', className, `${QA_PREFIX}`)}>
              <ScrollableContainer style={{ maxHeight: '20rem' }} className="mat-4 mab-2">
                <div className={cx(`${QA_PREFIX}__output--items`)}>
                  {renderFeed(resultsWhenDesktop)}
                </div>
              </ScrollableContainer>
            </div>
          )
        ) : null}
      </>
    )
  }
}

ActivityFeed.propTypes = {
  className: string,
  communityId: number,
  isMobileOnly: bool,
  tempReferralActivities: any,
  referralActivities: any,
  referralActivitiesRequestError: any,
  referralActivitiesRequestStatus: any,
  fetchCollection: func,
  setCollection: func,
  setCollectionRequestError: func,
  activityIdAdded: number,
  leadId: number,
  onFetchDataDone: func
}

ActivityFeed.defaultProps = {
  className: '',
  communityId: null,
  isMobileOnly: false,
  tempReferralActivities: null,
  referralActivities: {},
  referralActivitiesRequestError: null,
  referralActivitiesRequestStatus: true,
  fetchCollection: () => null,
  setCollection: () => null,
  setCollectionRequestError: () => null,
  activityIdAdded: null,
  leadId: null,
  onFetchDataDone: () => null
}

const mapStateToProps = createStructuredSelector({
  referralActivities: selectors.getCollection(
    REST_API_ENDPOINTS.referralActivities
  ),
  referralActivitiesRequestError: selectors.getResourceRequestError(
    REST_API_ENDPOINTS.referralActivities
  ),
  referralActivitiesRequestStatus: selectors.getResourceRequestStatus(
    REST_API_ENDPOINTS.referralActivities
  )
})

export default connect(
  mapStateToProps,
  actions
)(ActivityFeed)

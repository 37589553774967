import React from 'react'
import { node } from 'prop-types'

const Subheader = ({ children }) => (
  <h3 className="co-gray-3 pat-4">{children}</h3>
)

Subheader.propTypes = {
  children: node
}

Subheader.defaultProps = {
  children: null
}

export default Subheader
